import styled, { css } from "styled-components"
import React from "react"

export const StyledTerminologies = styled.h5`
  padding-bottom: 0.3em;
  color: ${props => props.theme.colors.green};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.green};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => props.color === "turquoise" && css`
    color: ${props => props.theme.colors.turquoise};
    border-bottom: 4px solid ${props => props.theme.colors.turquoise};
  `}
  ${props => props.color === "blue" && css`
    color: ${props => props.theme.colors.blue};
    border-bottom: 4px solid ${props => props.theme.colors.blue};
  `}
  ${props => props.color === "violet" && css`
    color: ${props => props.theme.colors.violet};
    border-bottom: 4px solid ${props => props.theme.colors.violet};
  `}
  ${props => props.color === "purple" && css`
    color: ${props => props.theme.colors.purple};
    border-bottom: 4px solid ${props => props.theme.colors.purple};
  `}
  ${props => props.color === "pink" && css`
    color: ${props => props.theme.colors.pink};
    border-bottom: 4px solid ${props => props.theme.colors.pink};
  `}
`

export const Terminologies = ({colorValue, children}) => {
  return (
    <StyledTerminologies color={colorValue}>{children}</StyledTerminologies>
  )
}
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Button from "../components/Button"
import TextHighlight from "../components/TextHighlight"
import Space from "../components/Space"
import Co2EmissionImg from "../img/intro/website-co2-emission.svg"
import BackstoryImg from "../img/intro/backstory-teaser.svg"
import SustainabilityIcon from "../icons/sustainability-icon.svg"
import SWDIcon from "../icons/sustainable-web-design-icon.svg"
import IntroIcon from "../icons/intro-icon.svg"
import MindsetIcon from "../img/intro/mindset-header-teaser-img.svg"
import ResourceIcon from "../img/intro/resource-header-teaser-img.svg"
import PracticeIcon from "../img/intro/best-practice_teaser_img.svg"
import scrollTo from "gatsby-plugin-smoothscroll"
import { graphql, Link } from "gatsby"
import {
  Anchor,
  AnchorLink,
  Header,
  HeaderPicture,
  SiteGoal,
} from "../components/HeaderSection"
import {
  FirstRole,
  RoleDescription,
  RoleHeadline,
  SecondRole,
} from "../components/BestPracticeStyled"
import styled from "styled-components"
import {
  CATSubheadline,
  CATUppercase,
  PTButton,
  PTHeadline,
} from "../components/CTASection"
import { Terminologies } from "../components/Terminologies"

const PTBackground = styled.div`
  z-index: -1;
  grid-column: 6 / span 9;
  grid-row: 15;
  background-color: ${props => props.theme.colors.shapes};
  height: auto;
  border-radius: 280px 0 0 0;
  @media screen and (max-width: 90rem) {
    grid-column: 4 / span 8;
    grid-row: 16;
    height: auto;
    border-radius: 200px 0 0 0;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 4 / span 5;
    grid-row: 19;
    height: auto;
    border-radius: 125px 0 0 0;
  }
`
const PTSection = styled.div`
  grid-column: 4 / span 7;
  grid-row: 15;

  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 4;
    grid-row: 16;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
    grid-row: 19;
  }
`
const PTImageSection = styled.div`
  grid-column: 11 / span 3;
  grid-row: 15;

  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 4;
    grid-row: 16;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
    grid-row: 19;
  }
`

const MindsetImage = styled.div`
  padding-bottom: 20rem;
  margin-top: 6rem;
  vertical-align: top;
  @media screen and (max-width: 90rem) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 1.5rem;
  }
`

const PracticeImage = styled.div`
  padding-bottom: 30rem;
  vertical-align: middle;
`
const ResourceImage = styled.div`
  vertical-align: bottom;
`
const FirstPTContent = styled.div`
  margin-top: 6rem;
  vertical-align: top;
  @media screen and (max-width: 90rem) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 1.5rem;
  }
`
const SecondPTContent = styled.div`
  vertical-align: middle;
`
const ThirdPTContent = styled.div`
  vertical-align: bottom;
`

const PTUppercase = styled.h2`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 5rem;
  vertical-align: top;
  text-transform: uppercase;
  @media screen and (max-width: 90rem) {
    padding-top: 6rem;
  }
  @media screen and (max-width: 37rem) {
    padding-top: 4rem;
  }
`

const PTSubheadline = styled.div`
  vertical-align: middle;
`

const CO2TextSection = styled.section`
  grid-column: 8 / span 6;
  grid-row: 18;
  padding-top: 25rem;
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
    padding-top: 18rem;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    padding-top: 2rem;
    grid-row: 22;
  }
`
const CO2PicSection = styled.h3`
  margin-top: -12rem;
  grid-column: 2 / span 5;
  grid-row: 18;
  @media screen and (max-width: 90rem) {
    margin: -6rem 5vw 0 2vw;
    grid-column: 2 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 3 / span 4;
    grid-row: 21;
    margin-top: -4rem;
  }
`
export const BiggerBody = styled.p`
  padding-top: 2rem;
  font-size: 1.5em;
  @media screen and (max-width: 90rem) {
    font-size: 1.3em;
    padding-top: 1rem;
  }
  @media screen and (max-width: 37rem) {
    font-size: 1em;
  }
`
const WWTextSection = styled.section`
  grid-column: 2 / span 6;
  grid-row: 20;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    padding-top: 2rem;
    grid-row: 24;
  }
`
const WWSubheadline = styled.h4`
  padding-top: 4rem;
  @media screen and (max-width: 90rem) {
    padding-top: 2rem;
  }
  @media screen and (max-width: 63rem) {
  }
`
const WWCircleIllustration = styled.footer`
  padding-top: 2.5rem;
  grid-column: 2 / span 12;
  grid-row: 21;
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
  }
  @media screen and (max-width: 63rem) {
    grid-row: 25;
  }
`
const SmallCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.tertiary};
  width: 175px;
  height: 175px;
  border-radius: 100%;
  @media screen and (max-width: 90rem) {
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width: 58rem) {
    width: 44px;
    height: 44px;
  }
`
const BigCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  width: 794.67px;
  height: 794.67px;
  border-radius: 100%;
  @media screen and (max-width: 90rem) {
    width: 680.34px;
    height: 680.34px;
  }
  @media screen and (max-width: 58rem) {
    width: 201.55px;
    height: 201.55px;
  }
`
const WWFirstConclusion = styled.section`
  grid-column: 2 / span 6;
  grid-row: 22;
  //margin-bottom: 12rem;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
    //margin-bottom: 5rem;
  }
  @media screen and (max-width: 63rem) {
    grid-row: 26;
  }
  @media screen and (max-width: 58rem) {
    grid-column: 2 / span 6;
    padding-top: 2rem;
    //margin-bottom: 2.5rem;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
  }
`
const SmallNumber = styled.h5`
  font-size: 0.5em;
  display: none;
  text-align: center;
  @media screen and (max-width: 58rem) {
    display: flex;
  }
`
const BigNumber = styled.h5`
  display: flex;
  @media screen and (max-width: 58rem) {
    display: none;
  }
`
const BackstoryPT = styled.div`
  margin-top: 9vw;
  grid-column: 2 / span 7;
  grid-row: 24;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 4;
  }
  @media screen and (max-width: 63rem) {
    margin-top: 23vw;
    grid-row: 28;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    grid-row: 29;
    margin-top: -1rem;
  }
`
const BackstoryPicture = styled.div`
  grid-column: 9 / span 4;
  grid-row: 24;

  @media screen and (max-width: 90rem) {
    grid-column: 6 / span 2;
  }
  @media screen and (max-width: 63rem) {
    grid-row: 28;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 5 / span 3;
  }
`
const BackstoryUppercase = styled.h2`
  vertical-align: top;
  text-transform: uppercase;
`
const BackstoryPTButton = styled.section`
  padding-top: 2rem;
  margin-left: 36vw;
  grid-row: 16;
  @media screen and (max-width: 90rem) {
    padding-top: 1rem;
    margin-left: 45vw;
  }
  @media screen and (max-width: 37rem) {
    padding-top: 0.5rem;
    margin-left: 0;
  }
`

export const MindsetPT = styled.div`
  grid-column: 4 / span 7;
  grid-row: 26;

  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 4;
  }
  @media screen and (max-width: 63rem) {
    grid-row: 31;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
    grid-row: 30;
  }
`
export const MindsetPTBackground = styled.div`
  z-index: -1;
  grid-column: 6 / span 9;
  grid-row: 26;
  background-color: ${props => props.theme.colors.shapes};
  height: auto;
  border-radius: 280px 0 0 0;
  @media screen and (max-width: 90rem) {
    grid-column: 4 / span 8;
    height: auto;
    border-radius: 200px 0 0 0;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 4 / span 5;
    grid-row: 31;
    height: auto;
    border-radius: 125px 0 0 0;
  }
  @media screen and (max-width: 37rem) {
    grid-row: 30;
  }
`
export const SmallIcon = styled.img`
  justify-self: end;
`

export default function Intro({ data }) {
  const smallerIconWidth = 600
  const isBrowser = () => typeof window !== "undefined"

  return (
    <Layout>
      <Seo
        title={
          "Make Web Sustainable – by reducing the CO2 emission of websites"
        }
        description={
          "Make Web Sustainable informs about sustainable web design and creates a sustainable mindset/toolset with the goal to reduce the CO2 emission of websites"
        }
        keywords={[
          "Make Web Sustainable",
          "Sustainable Web Design",
          "Home",
          "Introduction",
          "CO2 Emission",
        ]}
      />
      <Header>
        <div>
          <h1>
            <TextHighlight colorValue={"green"}>Why</TextHighlight>
            <br />
            Make Web
            <br />
            Sustain&shy;able?
          </h1>
          <h2>
            Because it is{" "}
            <TextHighlight colorValue={"green"}>not by default</TextHighlight>.
          </h2>
          <AnchorLink onClick={() => scrollTo("#intro-goal")}>
            <Button primaryGreen alignLeft>
              Show me why.
            </Button>
          </AnchorLink>
          <Link to="/mindset">
            <Button secundaryGreen alignRight>
              Show me how.
            </Button>
          </Link>
        </div>
      </Header>
      <HeaderPicture>
        <img
          src={IntroIcon}
          alt={"Header picture of a desktop with green screen"}
        />
      </HeaderPicture>
      <Anchor id={"intro-goal"} />
      <SiteGoal>
        <h2>
          Presenting you with information on sustainable web design in hopes of
          creating awareness and help laying a foundation for a sustainable web.
        </h2>
      </SiteGoal>
      <RoleHeadline>Terminology</RoleHeadline>
      <RoleDescription>
        To create a common basis, important terms are explained here.
      </RoleDescription>
      <FirstRole>
        <Terminologies>
          Sustainablility{" "}
          <SmallIcon
            src={SustainabilityIcon}
            width={"60px"}
            alt={"Small icon of a tree"}
          />
        </Terminologies>

        <p>
          Meeting our needs without compromising the chances of future
          generations to meet theirs.{" "}
          <Link to="/resources#sources">
            <TextHighlight colorValue={"smallSecundaryDark"}>[1]</TextHighlight>
          </Link>
        </p>
      </FirstRole>
      <SecondRole>
        <Terminologies>
          Sustainable web design
          <SmallIcon
            src={SWDIcon}
            width={"60px"}
            alt={"Small icon of a desktop with green screen"}
          />
        </Terminologies>
        <p>
          Primary reducing the carbon emissions of a web page by reducing its size.
        </p>
      </SecondRole>
      <Space big nine />
      <PTBackground />
      <PTSection>
        <FirstPTContent>
          <PTUppercase>
            <TextHighlight colorValue={"blue"}>Mindset</TextHighlight>
            {isBrowser() && window.innerWidth > smallerIconWidth ? (
                <Link to="/mindset" aria-label={"Brain waves icon"}><img
                width={"180px"}
                height={"auto"}
                src={MindsetIcon}
                alt={"Header picture of a desktop with green screen"}
                /></Link>
            ) : (
              <Link to="/mindset" aria-label={"Brain waves icon"}><img
                width={"180px"}
                height={"auto"}
                src={MindsetIcon}
                alt={"Header picture of a desktop with green screen"}
              /></Link>
            )}
          </PTUppercase>
          <PTHeadline>
            On the search for a{" "}
            <TextHighlight colorValue={"blue"}>design process</TextHighlight> ?
          </PTHeadline>
          <PTSubheadline>
            <BiggerBody>
              Check out the mindset page. It introduces a mindset with four core
              sustainable design principles and a design process based on that
              mindset.
            </BiggerBody>
          </PTSubheadline>
          <PTButton>
            <Link to="/mindset">
              <Button catPrimaryBlue alignLeft>
                Explore mindset
              </Button>
            </Link>
          </PTButton>
        </FirstPTContent>
        <SecondPTContent>
          <PTUppercase>
            <TextHighlight colorValue={"violet"}>Best Practices</TextHighlight>
            {isBrowser() && window.innerWidth > smallerIconWidth ? (
                <Link to="/best-practices" aria-label={"Dumbbell icon"}><img
                width={"180px"}
                height={"auto"}
                src={PracticeIcon}
                alt={"Header picture of a desktop with green screen"}
                /></Link>
            ) : (
              <Link to="/best-practices" aria-label={"Dumbbell icon"}><img
                width={"180px"}
                height={"auto"}
                src={PracticeIcon}
                alt={"Header picture of a desktop with green screen"}
              /></Link>
            )}
          </PTUppercase>
          <PTHeadline>
            You want to{" "}
            <TextHighlight colorValue={"violet"}>become active</TextHighlight> ?
          </PTHeadline>
          <PTSubheadline>
            <BiggerBody>
              Check out the best practice page. It presents best practices for
              reducing a website's carbon emissions which you can filter by
              roles.
            </BiggerBody>
          </PTSubheadline>
          <PTButton>
            <Link to="/best-practices">
              <Button catPrimaryViolet alignLeft>
                Explore practices
              </Button>
            </Link>
          </PTButton>
        </SecondPTContent>
        <ThirdPTContent>
          <PTUppercase>
            <TextHighlight colorValue={"pink"}>Resources</TextHighlight>
            {isBrowser() && window.innerWidth > smallerIconWidth ? (
              <Link to="/resources" aria-label={"Compass icon"}><img
                width={"180px"}
                height={"auto"}
                src={ResourceIcon}
                alt={"Header picture of a desktop with green screen"}
                /></Link>
            ) : (
              <Link to="/resources" aria-label={"Compass icon"}><img
                width={"180px"}
                height={"auto"}
                src={ResourceIcon}
                alt={"Header picture of a desktop with green screen"}
              /></Link>
            )}
          </PTUppercase>
          <PTHeadline>
            You are <TextHighlight colorValue={"pink"}>hooked</TextHighlight>
            <br />
            on the topic?
          </PTHeadline>
          <PTSubheadline>
            <BiggerBody>
              Check out the resources page. It presents high quality resources
              on sustainable web design which you can filter by category.
            </BiggerBody>
          </PTSubheadline>
          <PTButton>
            <Link to="/resources">
              <Button catPrimaryPink alignLeft>
                Explore resources
              </Button>
            </Link>
          </PTButton>
        </ThirdPTContent>
      </PTSection>
      <Space big seventeen />
      <CO2PicSection>
        <img
          src={Co2EmissionImg}
          alt={"Co2Emission"}
          width={"100%"}
          height={"auto"}
        />
      </CO2PicSection>
      <CO2TextSection>
        <h3>
          Websites{" "}
          <TextHighlight colorValue={"green"}>produce CO2</TextHighlight>?
        </h3>
        <BiggerBody>
          Even though we can't measure the exact CO2 emission of a website, there
          are two values which can be used for approximately measurements.
          The first being Data transfer. The larger the data transfer the more energy is used in
          the data centers, the transmission networks and the end user devices.
          This leads to a higher CO2 consumption overall.
          <br /> <br />
          Secondly the CO2 emissions of a website also depend on the carbon
          intensity of the electricity used to power it. The higher the carbon
          intensity, the higher the CO2 emissions per Kilowatt hour of
          electricity (gCO2/kWh).
        </BiggerBody>
        <h4>
          Therefore the answer is{" "}
          <TextHighlight colorValue={"green"}>YES – they do</TextHighlight>.{" "}
          <TextHighlight colorValue={"green"}>
            The bigger the website the more CO2 is produced
          </TextHighlight>
          .
        </h4>
      </CO2TextSection>
      <Space big nineteen />
      <WWTextSection>
        <h3>
          Wait <TextHighlight colorValue={"green"}>whaaat</TextHighlight>?
        </h3>
        <WWSubheadline>
          <TextHighlight colorValue={"green"}>#1st </TextHighlight>Head trip
        </WWSubheadline>
        <BiggerBody>
          Size comparison of an average desktop website from 2010 (left side)
          and 2021 (right side).{" "}
          <Link to="/resources#sources">
          <TextHighlight colorValue={"smallSecundaryDark"}>
            [3]
          </TextHighlight></Link>
        </BiggerBody>
      </WWTextSection>
      <WWCircleIllustration>
        <SmallCircle>
          <SmallNumber>
            <TextHighlight colorValue={"shapes"}>
              467,7
              <br />
              KB
            </TextHighlight>
          </SmallNumber>
          <BigNumber>
            <TextHighlight colorValue={"shapes"}>467,7 KB</TextHighlight>
          </BigNumber>
        </SmallCircle>
        <BigCircle>
          <h6>
            <TextHighlight colorValue={"shapes"}>2123,6 KB</TextHighlight>
          </h6>
        </BigCircle>
      </WWCircleIllustration>
      <WWFirstConclusion>
        <h4>
          Right – nowadays an average website is{" "}
          <TextHighlight colorValue={"green"}>over 350% bigger</TextHighlight>{" "}
          than back in 2010.
        </h4>
      </WWFirstConclusion>
      <Space big twentythree />
      <BackstoryPicture>
        <img
          src={BackstoryImg}
          alt={"Backstory"}
          width={"100%"}
          height={"auto"}
        />
      </BackstoryPicture>
      <BackstoryPT>
        <BackstoryUppercase>
          <TextHighlight colorValue={"green"}>Backstory</TextHighlight>
        </BackstoryUppercase>
        <PTHeadline>
          Curious about the{" "}
          <TextHighlight colorValue={"green"}>backstory</TextHighlight>?
        </PTHeadline>
        <PTSubheadline>
          <BiggerBody>
            Check out the backstory page to learn how this website came to life
            and take a look behind-the-scenes of my current sustainability journey so
            far.
          </BiggerBody>
        </PTSubheadline>
        <BackstoryPTButton>
          <Link to="/backstory">
            <Button catPrimaryGreen alignLeft>
              Explore backstory
            </Button>
          </Link>
        </BackstoryPTButton>
      </BackstoryPT>
      <Space big iTwentyfive />
      <MindsetPTBackground />
      <MindsetPT>
        <CATUppercase>
          <TextHighlight colorValue={"blue"}>Up Next</TextHighlight>
        </CATUppercase>
        <PTHeadline>
          You want to see a{" "}
          <TextHighlight colorValue={"blue"}>solution proposal</TextHighlight>?
        </PTHeadline>
        <CATSubheadline>
          Take a look at a sustainable mindset & process with four core design
          principles.
        </CATSubheadline>
        <PTButton>
          <Link to="/mindset">
            <Button catPrimaryBlue alignLeft>
              Explore mindset
            </Button>
          </Link>
        </PTButton>
      </MindsetPT>
    </Layout>
  )
}

export const query = graphql`
  query SeoSiteInfo {
    site {
      siteMetadata {
        title
      }
    }
  }
`

import styled from "styled-components"

export const PTHeadline = styled.h3`
  padding: 2rem 0 0 0;
  vertical-align: middle;
  @media screen and (max-width: 90rem) {
    padding: 1rem 0;
  }
  @media screen and (max-width: 37rem) {
    padding: 0.5rem 0;
  }
`
export const PTButton = styled.section`
  padding-top: 2rem;
  margin-left: 36vw;
  grid-row: 16;
  padding-bottom: 8rem;
  @media screen and (max-width: 90rem) {
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-left: 42vw;
  }
  @media screen and (max-width: 37rem) {
    padding-top: 0.5rem;
    padding-bottom: 3rem;
    margin-left: 0;
  }
`
export const CATUppercase = styled.h2`
  padding-top: 9rem;
  text-transform: uppercase;
  grid-column: 2 / span 6;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
  }
  @media screen and (max-width: 63rem) {
  }
`
export const CATSubheadline = styled.h2`
  padding-top: 2rem;
`
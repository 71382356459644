import styled from "styled-components"

export const RoleHeadline = styled.h3`
  grid-column: 2 / span 7;
  grid-row: 7;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
  }
`
export const RoleDescription = styled.h4`
  grid-column: 2 / span 4;
  font-size: 1.5em;
  grid-row: 8;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: right;
  color: ${props => props.theme.colors.tertiary};
  text-transform: uppercase;

  @media screen and (max-width: 90rem) {
    font-size: 1.1em;
    margin-top: 20px;
    margin-bottom: 10px;
    grid-column: 2 / span 3;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    font-size: 1em;
    text-align: left;
  }
`
export const FirstRole = styled.section`
  grid-column: 6 / span 4;
  grid-row: 8;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 5 / span 3;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 9;
    text-align: left;
  }
`
export const SustainabilityDirector = styled.h5`
  padding-bottom: 0.3em;
  letter-spacing: 0.03em;
  color: ${props => props.theme.colors.turquoise};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.turquoise};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const SecondRole = styled.section`
  grid-column: 10 / span 4;
  grid-row: 8;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 2 / span 3;
    grid-row: 9;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 10;
    text-align: left;
  }
`
export const Developer = styled.h5`
  padding-bottom: 0.3em;
  color: ${props => props.theme.colors.blue};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.blue};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ThirdRole = styled.section`
  grid-column: 2 / span 4;
  grid-row: 9;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 5 / span 3;
    grid-row: 9;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 11;
    text-align: left;
  }
`
export const InformationArchitect = styled.h5`
  padding-bottom: 0.3em;
  color: ${props => props.theme.colors.violet};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.violet};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const FourthRole = styled.section`
  grid-column: 6 / span 4;
  grid-row: 9;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 2 / span 3;
    grid-row: 10;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 12;
    text-align: left;
  }
`
export const VisualDesigner = styled.h5`
  padding-bottom: 0.3em;
  color: ${props => props.theme.colors.purple};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.purple};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const FifthRole = styled.section`
  grid-column: 10 / span 4;
  grid-row: 9;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 90rem) {
    margin-top: 25px;
    margin-bottom: 25px;
    grid-column: 5 / span 3;
    grid-row: 10;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2 / span 6;
    grid-row: 13;
    text-align: left;
  }
`
export const InteractionDesigner = styled.h5`
  padding-bottom: 0.3em;
  color: ${props => props.theme.colors.pink};
  text-transform: uppercase;
  border-bottom: 4px solid ${props => props.theme.colors.pink};
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CardHeadline = styled.h3`
  grid-column: 2 / span 12;
  grid-row: 11;
  margin-bottom: 0;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    grid-row: 12;
    margin-bottom: 0.5vw;
  }
  @media screen and (max-width: 37rem) {
    grid-row: 15;
  }
`
export const CardSection = styled.section`
  z-index: 1;
  margin-top: 1.4vw;
  display: grid;
  grid-row: 14;
  grid-column-start: 2;
  grid-column-end: 14;
  grid-template-columns: 24.8vw 24.8vw 24.8vw;
  grid-template-rows: auto;
  grid-gap: 1.4vw 1.4vw;

  @media screen and (max-width: 90rem) {
    display: grid;
    grid-row: 15;
    margin-top: 2vw;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 28vw 28vw 28vw;
    grid-template-rows: auto;
    grid-gap: 2vw 2vw;
  }

  @media screen and (max-width: 58rem) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 43vw 43vw;
    grid-template-rows: auto;
    grid-gap: 2vw 2vw;
  }
  @media screen and (max-width: 50rem) {
    margin-top: 2vw;
    grid-row: 20;
  }
  @media screen and (max-width: 46rem) {
    margin-top: 4vw;
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 88vw;
    grid-template-rows: auto;
    grid-gap: 6vw;
  }
  @media screen and (max-width: 37rem) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 93vw;
    grid-template-rows: auto;
    grid-gap: 6vw;
  }
`
export const Card = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.cards};
  box-shadow: ${props => props.theme.shadows.shadow1};
  height: 24.8vw;
  border-radius : 0 0 100px 0;
  @media screen and (max-width: 102rem) {
    height: 30vw;
    border-radius : 0 0 90px 0;
  }
  @media screen and (max-width: 90rem) {
    height: 43vw;
    border-radius : 0 0 90px 0;
  }
  @media screen and (max-width: 46rem) {
    border-radius : 0 0 80px 0;
    height: 93vw;
  }
  @media screen and (max-width: 23rem) {
    border-radius : 0 0 60px 0;
    height: 110vw;
  }
`

export const CardContent = styled.div`
  max-height: 21vw;

  @media screen and (max-width: 90rem) {
    max-height: 21vw;

  }
  @media screen and (max-width: 46rem) {
    max-height: 21vw;
  }
`

export const CardTitle = styled.p`
  vertical-align: top;
  padding: 6vw 2vw 0 2vw;
  font-family: "Archivo Black", sans-serif;
  line-height: 1.3em;
  letter-spacing: 0.09em;
  font-size: 1.87em;
  @media screen and (max-width: 90rem) {
    font-size: 1.45em;
    padding: 8vw 3.5vw 0 3.5vw;
  }
  @media screen and (max-width: 46rem) {
    font-size: 1.5em;
    padding: 20vw 6vw 0 6vw;
  }
  @media screen and (max-width: 21rem) {
    padding: 10vw 6vw 0 6vw;
  }
`
export const CardDescription = styled.p`
  vertical-align: middle;
  padding: 0.5vw 2vw 0 2vw;
  color: ${props => props.theme.colors.secundaryLight};
  @media screen and (max-width: 90rem) {
    padding: 1vw 3.5vw 0 3.5vw;
    font-size: 1.0em;
  }
  @media screen and (max-width: 46rem) {
    font-size: 1.0em;
    padding: 4vw 6vw 0 6vw;
  }
`
export const CardRole = styled.p`
  position: absolute;
  bottom: 2vw;
  left: 2vw;
  padding: 2vw 0 0 0;
  font-size: 1em;
  text-transform: uppercase;
  @media screen and (max-width: 90rem) {
    position: absolute;
    bottom: 3.5vw;
    left: 3.5vw;
    padding: 3vw 0 0 0;
    font-size: 0.8em;
  }
  @media screen and (max-width: 46rem) {
    position: absolute;
    bottom: 6vw;
    left: 6vw;
    padding: 8vw 0 0 0;
    font-size: 0.9em;
  }
`
export const CTASubheadline = styled.section`
  grid-column: 3 / span 6;
  padding-top: 26em;
  grid-row: 15;
  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 3;
    grid-row: 16;
    padding-top: 15em;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
    grid-row: 19;
    padding-top: 9em;
  }
`

export const ResourcesPT = styled.div`
  grid-column: 4 / span 7;
  grid-row: 26;

  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 4;
  }
  @media screen and (max-width: 63rem) {
    grid-row: 31;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
    grid-row: 30;
  }
`
export const ResourcesPTBackground = styled.div`
  z-index: -1;
  grid-column: 6 / span 9;
  grid-row: 26;
  background-color: ${props => props.theme.colors.shapes};
  height: auto;
  border-radius: 280px 0 0 0;
  @media screen and (max-width: 90rem) {
    grid-column: 4 / span 8;
    height: auto;
    border-radius: 200px 0 0 0;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 4 / span 5;
    grid-row: 31;
    height: auto;
    border-radius: 125px 0 0 0;
  }
  @media screen and (max-width: 37rem) {
    grid-row: 30;
  }
`
